import { ApolloClient, gql, Reference } from "@apollo/client";

import { COLLECTION_FIELDS, VEHICLE_CARD_FIELDS } from "constants/fragments";
import { ApolloCacheService } from "services/ApolloCacheService";
import { Collection } from "typings/Collection";
import { CollectionService } from "typings/CollectionService";
import { Collector } from "typings/Collector";
import { Media } from "typings/Media";
import { Vehicle } from "typings/Vehicle";

const FETCH_COLLECTIONS = gql`
  ${COLLECTION_FIELDS}
  query FetchCollections($collectorId: ID!) {
    collections(collectorId: $collectorId) {
      ...CollectionFields
    }
  }
`;

const DELETE_COLLECTION = gql`
  mutation DeleteCollection($id: ID!) {
    deleteCollection(id: $id) {
      void
    }
  }
`;

const ADD_COLLECTION = gql`
  ${COLLECTION_FIELDS}
  mutation AddCollection(
    $title: String!
    $description: String
    $profilePictureId: ID
    $coverPictureId: ID
  ) {
    addCollection(
      collectionData: {
        title: $title
        description: $description
        profilePictureId: $profilePictureId
        coverPictureId: $coverPictureId
      }
    ) {
      ...CollectionFields
    }
  }
`;

export const UPDATE_COLLECTION = gql`
  ${COLLECTION_FIELDS}
  mutation UpdateCollection(
    $id: ID!
    $title: String!
    $description: String
    $profilePictureId: ID
    $coverPictureId: ID
  ) {
    updateCollection(
      collectionData: {
        id: $id
        title: $title
        description: $description
        profilePictureId: $profilePictureId
        coverPictureId: $coverPictureId
      }
    ) {
      ...CollectionFields
    }
  }
`;

const SET_VEHICLES_TO_COLLECTION = gql`
  ${VEHICLE_CARD_FIELDS}
  mutation SetVehiclesToCollection($id: ID!, $vehicleIds: [ID!]!) {
    setVehiclesToCollection(
      setVehiclesToCollectionData: { id: $id, vehicles: $vehicleIds }
    ) {
      id
      vehicleCount
      vehicles {
        ...VehicleCardFields
      }
    }
  }
`;

const FETCH_PUBLIC_COLLECTIONS = gql`
  ${COLLECTION_FIELDS}
  query FetchPublicCollections(
    $limit: Int
    $toSize: Int
    $fromSize: Int
    $makes: [String!]
  ) {
    publicCollections(
      limit: $limit
      toSize: $toSize
      fromSize: $fromSize
      makes: $makes
    ) {
      ...CollectionFields
    }
  }
`;

export default class ApolloCollectionService implements CollectionService {
  constructor(private apolloClient: ApolloClient<any>) {}

  async fetchByCollector(collectorId: Collector["id"]): Promise<Collection[]> {
    const { data, errors } = await this.apolloClient.query<{
      collections: Collection[];
    }>({
      query: FETCH_COLLECTIONS,
      variables: { collectorId },
    });

    if (errors) {
      throw errors[0];
    }

    return data.collections;
  }

  async delete(id: Collection["id"]): Promise<void> {
    const { errors } = await this.apolloClient.mutate<{
      deleteCollection: Collection;
    }>({
      mutation: DELETE_COLLECTION,
      variables: { id },
    });

    if (errors) {
      throw errors[0];
    }

    this.apolloClient.cache.evict({ id: `Collection:${id}` });
  }

  async add(
    collection: Partial<Collection>,
    profilePicture?: Media,
    coverPicture?: Media,
  ): Promise<Collection["id"]> {
    const { data, errors } = await this.apolloClient.mutate<{
      addCollection: Collection;
    }>({
      mutation: ADD_COLLECTION,
      variables: {
        ...collection,
        profilePictureId: profilePicture?.id,
        coverPictureId: coverPicture?.id,
      },
    });

    if (errors) {
      throw errors[0];
    }

    if (data) {
      this.apolloClient.cache.modify<{ collections: Reference[] }>({
        fields: {
          collections: (existingCollections, { toReference }) => [
            toReference(data.addCollection) as Reference,
            ...existingCollections,
          ],
        },
      });
    }

    return data?.addCollection?.id;
  }

  async update(
    collection: Partial<Collection>,
    profilePicture?: Media,
    coverPicture?: Media,
  ): Promise<void> {
    const { errors } = await this.apolloClient.mutate({
      mutation: UPDATE_COLLECTION,
      variables: {
        ...collection,
        profilePictureId: profilePicture?.id,
        coverPictureId: coverPicture?.id,
      },
    });

    if (errors) {
      throw errors[0];
    }
  }

  async setVehicles(
    id: Collection["id"],
    vehicleIds: Array<Vehicle["id"]>,
  ): Promise<void> {
    const CACHED_COLLECTION_QUERY = gql`
      query CachedCollection($collectionId: ID!) {
        collection(id: $collectionId) {
          vehicles {
            id
          }
        }
      }
    `;

    const cached = this.apolloClient.readQuery({
      query: CACHED_COLLECTION_QUERY,
      variables: { collectionId: id },
    });

    const { errors } = await this.apolloClient.mutate({
      mutation: SET_VEHICLES_TO_COLLECTION,
      variables: { vehicleIds, id },
    });

    if (errors) {
      throw errors[0];
    }

    const affectedVehicleIds = [
      ...vehicleIds,
      ...cached.collection.vehicles.map((vehicle: any) => vehicle.id),
    ];

    affectedVehicleIds.forEach((affectedVehicleId) => {
      this.apolloClient.cache.modify<Vehicle>({
        id: `Vehicle:${affectedVehicleId}`,
        fields: {
          collections: (_, { DELETE }) => DELETE,
          collectionsIds: (_, { DELETE }) => DELETE,
        },
      });
    });

    ApolloCacheService.evictDashboardCollection(this.apolloClient.cache);
  }

  async fetchPublicAll(
    makes?: Array<Vehicle["make"]>,
    fromSize?: Collection["vehicleCount"] | null,
    toSize?: Collection["vehicleCount"] | null,
  ): Promise<Collection[]> {
    const { data, errors } = await this.apolloClient.query<{
      publicCollections: Collection[];
    }>({
      query: FETCH_PUBLIC_COLLECTIONS,
      variables: { makes, fromSize, toSize },
    });

    if (errors) {
      throw errors[0];
    }

    return data.publicCollections;
  }
}
