export const Language = {
  EN: "en",
  DE: "de",
} as const;

export type Language = (typeof Language)[keyof typeof Language];

export const validLanguages: Language[] = Object.values(Language);

export const DEFAULT_LANGUAGE: NonNullable<Language> = Language.EN;
