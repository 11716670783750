import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";

import { useMessaging } from "contexts/Messaging/useMessagingContext";
import { reload } from "utils/window";

const POLL_INTERVAL = import.meta.env.REACT_APP_VERSION_POLL_INTERVAL
  ? Number(import.meta.env.REACT_APP_VERSION_POLL_INTERVAL)
  : 60 * 1000;

const BASE_URL = import.meta.env.REACT_APP_BASE_URL;

/**
 * production: frontend-v<SEM_VERSION>
 * staging: commit SHA
 */
const VERSION = import.meta.env.REACT_APP_VERSION;

export function VersionChecker() {
  const { t } = useTranslation();

  const { enqueueMessage } = useMessaging();

  const [availableVersion, setAvailableVersion] = useState("");

  useEffect(() => {
    const getVersion = () => {
      if (BASE_URL && VERSION) {
        fetch(new URL("version.txt", BASE_URL), {
          cache: "no-cache",
        })
          .then((resp) => resp.text())
          .then((data) => {
            setAvailableVersion(data);
          });
      }
    };

    const interval = setInterval(getVersion, POLL_INTERVAL);
    getVersion();

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (availableVersion && VERSION && VERSION !== availableVersion) {
      enqueueMessage({
        text: t(`newVersionAvailable`),
        variant: "info",
        persist: true,
        action: (
          <Button
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => reload({ force: true })}
          >
            {t(`reload`)}
          </Button>
        ),
      });
    }
  }, [availableVersion]);

  return (
    <Helmet>
      <meta content={VERSION} property="version" />
    </Helmet>
  );
}
