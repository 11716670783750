/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import { initReactI18next } from "react-i18next";
import i18n from "i18next";

import { DEFAULT_LANGUAGE, Language, validLanguages } from "typings/Language";

import de from "./locales/de.json";
import en from "./locales/en.json";

i18n.use(initReactI18next).init({
  fallbackLng: DEFAULT_LANGUAGE,
  resources: {
    [Language.EN]: { translations: en },
    [Language.DE]: { translations: de },
  },
  ns: ["translations"],
  defaultNS: "translations",
  interpolation: {
    escapeValue: false,
  },
  debug: process.env.NODE_ENV === "development",
});

i18n.languages = validLanguages;

export default i18n;
