import { AuthenticationService } from "typings/AuthenticationService";
import { Jwt } from "typings/Jwt";
import { Organizations } from "typings/Organization";
import {
  isAdmin as isRoleAdmin,
  isCollector as isRoleCollector,
  isConcierge as isRoleConcierge,
  isDealer as isRoleDealer,
  isOrganizationManager as isRoleOrganizationManager,
  Role,
} from "typings/Role";
import { decodeToken } from "utils/decodeToken";

export const ACCESS_TOKEN_KEY = "@@driven-at";
export const REFRESH_TOKEN_KEY = "@@driven-rt";

function hasNotExpired(exp?: number) {
  if (!exp) return false;

  return exp > Date.now() / 1000;
}

export default class LocalStorageAuthenticationService
  implements AuthenticationService
{
  private accessJwt: Jwt | undefined;

  private refreshJwt: Jwt | undefined;

  constructor() {
    this.accessJwt = decodeToken(this.getAccessToken());
    this.refreshJwt = decodeToken(this.getRefreshToken());
  }

  getAccessToken() {
    const value = localStorage.getItem(ACCESS_TOKEN_KEY);

    return value;
  }

  setAccessToken(token?: string) {
    if (token) localStorage.setItem(ACCESS_TOKEN_KEY, token);
    else localStorage.setItem(ACCESS_TOKEN_KEY, "");

    this.accessJwt = decodeToken(token);
  }

  clearTokens() {
    this.setAccessToken();
    this.setRefreshToken();
  }

  getRefreshToken() {
    const value = localStorage.getItem(REFRESH_TOKEN_KEY);

    return value;
  }

  setRefreshToken(token?: string) {
    if (token) localStorage.setItem(REFRESH_TOKEN_KEY, token);
    else localStorage.setItem(REFRESH_TOKEN_KEY, "");

    this.refreshJwt = decodeToken(token);
  }

  getAuthUserId(): string | undefined {
    return this.accessJwt?.sub;
  }

  getOnBehalfOfId() {
    return this.accessJwt?.obo?.prn;
  }

  getOrganizationsIds() {
    return this.accessJwt?.orgs as Organizations[];
  }

  getRole() {
    return this.accessJwt?.role as Role;
  }

  getTierLevel() {
    return this.accessJwt?.tierLevel;
  }

  hasValidRefreshToken() {
    return hasNotExpired(this.refreshJwt?.exp);
  }

  hasValidToken() {
    return hasNotExpired(this.accessJwt?.exp) || this.hasValidRefreshToken();
  }

  isAuthenticated() {
    return !this.isMfaRequired() && this.hasValidToken();
  }

  isAdmin() {
    return isRoleAdmin(this.getRole()) && this.hasValidToken();
  }

  isCollector() {
    return isRoleCollector(this.getRole()) && this.hasValidToken();
  }

  isConcierge() {
    return isRoleConcierge(this.getRole()) && this.hasValidToken();
  }

  isDealer() {
    return isRoleDealer(this.getRole()) && this.hasValidToken();
  }

  isMfaRequired() {
    return Boolean(this.accessJwt?.mfa);
  }

  isOrganizationManager() {
    return isRoleOrganizationManager(this.getRole()) && this.hasValidToken();
  }

  hasOrganizationPrivileges() {
    return (
      this.isOrganizationManager() || this.isConcierge() || this.isDealer()
    );
  }
}
