export type CollectionSortingOptions =
  | "titleAZ"
  | "titleZA"
  | "vehicleCountHighest"
  | "vehicleCountLowest";

export type LocationSortingOptions = CollectionSortingOptions;

export type VehicleSortingOptions =
  | "addedNewest"
  | "addedOldest"
  | "makeAZ"
  | "odometerHighest"
  | "odometerLowest"
  | "productionYearNewest"
  | "productionYearOldest"
  | "valuationHighest"
  | "valuationLowest";

export type ListingSortingOptions =
  | "makeAZ"
  | "productionYearOldest"
  | "productionYearNewest"
  | "listedNewest"
  | "listedOldest"
  | "priceHighest"
  | "priceLowest"
  | "odometerHighest"
  | "odometerLowest";

export type NegotiationsSortingOptions =
  | "updatedAt"
  | "status"
  | "role"
  | "priceHighest"
  | "priceLowest";

export type CollectorsSortingOptions =
  | "emailAZ"
  | "usernameAZ"
  | "nameAZ"
  | "surnameAZ"
  | "registeredLatest"
  | "registeredOldest";

export type UsageHistorySortingOptions =
  | "emailAZ"
  | "nameAZ"
  | "vehicleCountLowest"
  | "vehicleCountHighest"
  | "readonlyVehicleCountLowest"
  | "readonlyVehicleCountHighest"
  | "crewMembersCountHighest"
  | "crewMembersCountLowest"
  | "registeredLatest"
  | "registeredOldest"
  | "surnameAZ"
  | "usernameAZ";

export type SortingParameter<TOptions> = {
  sortBy: TOptions;
};

export type CollectionSorting = SortingParameter<CollectionSortingOptions>;
export type CollectorsSorting = SortingParameter<CollectorsSortingOptions>;
export type ListingSorting = SortingParameter<ListingSortingOptions>;
export type LocationSorting = SortingParameter<LocationSortingOptions>;
export type NegotiationsSorting = SortingParameter<NegotiationsSortingOptions>;
export type UsageHistorySorting = SortingParameter<UsageHistorySortingOptions>;
export type VehicleSorting = SortingParameter<VehicleSortingOptions>;

export enum SortingScope {
  collection = "collection",
  collectors = "collectors",
  listing = "listing",
  negotiations = "negotiations",
  usageHistory = "usageHistory",
  vehicle = "vehicle",
}

export type SortingSettings = {
  [SortingScope.collection]: CollectionSorting;
  [SortingScope.collectors]: CollectorsSorting;
  [SortingScope.listing]: ListingSorting;
  [SortingScope.negotiations]: NegotiationsSorting;
  [SortingScope.usageHistory]: UsageHistorySorting;
  [SortingScope.vehicle]: VehicleSorting;
};
