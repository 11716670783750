import { jwtDecode } from "jwt-decode";

import { Jwt } from "typings/Jwt";

export function decodeToken(token?: string | null) {
  if (!token) return undefined;

  try {
    return jwtDecode<Jwt>(token);
  } catch {
    return undefined;
  }
}
