import { lazy } from "react";

import { SuspenseWrapper } from "components/Layout/SuspenseWrapper";
import { useModalWithSubscription } from "hooks/useModalWithSubscription";
import { Entity } from "typings/Entity";
import { Location } from "typings/Location";
import { DialogTopics, LocationTopics } from "typings/Topics";
const LocationManageVehiclesModal = lazy(
  () => import("./LocationManageVehiclesModal"),
);
const SaveLocationModal = lazy(() => import("./SaveLocationModal"));
const EditGalleryModal = lazy(
  () => import("components/EditGalleryModal/EditGalleryModal"),
);

export const LocationDialogs = () => {
  const { object: toManage, handleClose: handleCloseManage } =
    useModalWithSubscription<Location>(
      DialogTopics.ManageLocation,
      LocationTopics.VehiclesUpdated,
    );

  const { object: toEdit, handleClose: handleCloseEdit } =
    useModalWithSubscription<Entity>(
      DialogTopics.EditLocation,
      LocationTopics.Updated,
    );

  const { object: toEditGallery, handleClose: handleCloseGalleryEdit } =
    useModalWithSubscription<Entity>(DialogTopics.EditLocationGallery);

  return (
    <>
      {toManage && (
        <SuspenseWrapper>
          <LocationManageVehiclesModal
            location={toManage}
            onClose={handleCloseManage}
          />
        </SuspenseWrapper>
      )}

      {toEdit && (
        <SuspenseWrapper>
          <SaveLocationModal {...toEdit} onClose={handleCloseEdit} />
        </SuspenseWrapper>
      )}

      {toEditGallery && (
        <SuspenseWrapper>
          <EditGalleryModal
            {...toEditGallery}
            entityType="location"
            onClose={handleCloseGalleryEdit}
          />
        </SuspenseWrapper>
      )}
    </>
  );
};
