import { useMemo } from "react";

type EncryptionWorker = typeof import("utils/encryption");

const EncryptionWorker = new ComlinkWorker<EncryptionWorker>(
  new URL("utils/encryption", import.meta.url),
  {
    name: "EncryptionWorker",
    type: "module",
  },
);

export const useEncryptionWorker = () => {
  const worker = useMemo(() => EncryptionWorker, []);

  return worker;
};
