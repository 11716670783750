import { useEffect, useState } from "react";

type DelayedWrapperProps = {
  delay?: number;
  children?: React.ReactNode;
};

export const DelayedWrapper = ({
  delay = 300,
  children,
}: DelayedWrapperProps) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  return show ? <> {children} </> : null;
};
