import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { gql, useMutation } from "@apollo/client";

import { useMessaging } from "contexts/Messaging/useMessagingContext";
import { Mfa } from "typings/Mfa";

const REFRESH_TTL_MILLISECONDS = 5 * 60 * 1000;

const REFRESH_MFA = gql`
  mutation RefreshMfa($mfaMethod: String!, $credentialId: String) {
    refreshMfa(mfaMethod: $mfaMethod, credentialId: $credentialId) {
      method
      qrCode
    }
  }
`;

export const useRefreshMfa = () => {
  const { t } = useTranslation();
  const { enqueueMessage } = useMessaging();

  const [error, setError] = useState<any>();
  const [emailTtl, setEmailTtl] = useState<number>(0);
  const [qrCode, setQrCode] = useState("");
  const [method, setMethod] = useState<Mfa["method"] | null>(null);

  const [mutate, { loading }] = useMutation<{
    refreshMfa: { method: Mfa["method"]; qrCode: string };
  }>(REFRESH_MFA);

  const refreshMfa = useCallback(
    async (mfaMethod: Mfa["method"], force = false) => {
      if (!force && emailTtl >= Date.now() && mfaMethod === "email") {
        return;
      }

      setMethod(mfaMethod);

      const { data, errors } = await mutate({
        variables: { mfaMethod },
      });

      if (errors) {
        if (method === "email") {
          enqueueMessage({
            text: t("emailNotSent"),
            variant: "error",
          });
        }
        setError(errors[0]);
      }

      if (data?.refreshMfa) {
        setQrCode(data.refreshMfa.qrCode);

        if (method === "email") {
          setEmailTtl(Date.now() + REFRESH_TTL_MILLISECONDS);
          enqueueMessage({
            text: t("emailSent"),
            variant: "success",
          });
        }
      }
    },
    [emailTtl],
  );

  return { refreshMfa, refreshMfaState: { loading, error, qrCode } };
};
