import { ComponentType, ReactNode } from "react";
import { Controller, useFormContext } from "react-hook-form";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import { ClearIcon } from "@mui/x-date-pickers";

import { TextField, TextFieldProps } from "./TextField";

export type TextFieldControlledProps = TextFieldProps & {
  clearable?: boolean;
  component?: ComponentType<TextFieldProps>;
  name: string;
};

type ClearButtonProps = {
  onClick: () => void;
  children?: ReactNode;
  value?: string;
};

const ClearButton = ({ onClick, children, value }: ClearButtonProps) => (
  <InputAdornment
    position="end"
    sx={{ display: "inline-flex", alignItems: "center" }}
  >
    <>
      {value && (
        <IconButton size="small" onClick={onClick}>
          <ClearIcon fontSize="small" />
        </IconButton>
      )}

      {children && children}
    </>
  </InputAdornment>
);

export const TextFieldControlled = ({
  clearable,
  component,
  error: errorProp,
  helperText,
  name,
  ...rest
}: TextFieldControlledProps) => {
  const { control } = useFormContext();

  const Input = component ?? TextField;

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value = "" },
        fieldState: { error, invalid },
      }) => (
        <Input
          {...rest}
          InputProps={{
            ...rest?.InputProps,
            inputProps: {
              "data-testid": `${name}-input`,
              ...rest?.InputProps?.inputProps,
            },
            endAdornment: (
              <Stack alignItems="center" direction="row" gap={0.5}>
                {clearable && (
                  <ClearButton value={value} onClick={() => onChange("")} />
                )}
                {rest?.InputProps?.endAdornment}
              </Stack>
            ),
          }}
          error={invalid || !!errorProp}
          helperText={error?.message ?? helperText}
          name={name}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      )}
    />
  );
};
