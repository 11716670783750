import { lazy } from "react";
import { Outlet } from "react-router-dom";

import { Layout, LayoutProps } from "components/Layout/Layout";
import { SuspenseWrapper } from "components/Layout/SuspenseWrapper";
import { VERTICAL_APP_BAR_WIDTH } from "constants/layout";
import { useNotifyOnTextDetectionProgress } from "hooks/useNotifyOnTextDetectionProgress";

const AuthenticatedAppBar = lazy(
  () => import("components/Layout/AuthenticatedAppBar"),
);

const AuthenticatedLayout = ({ maxWidth }: LayoutProps) => {
  useNotifyOnTextDetectionProgress();

  return (
    <Layout
      AppBar={AuthenticatedAppBar}
      maxWidth={maxWidth ?? "xl"}
      verticalAppBarWidth={VERTICAL_APP_BAR_WIDTH}
    >
      <SuspenseWrapper>
        <Outlet />
      </SuspenseWrapper>
    </Layout>
  );
};

export default AuthenticatedLayout;
