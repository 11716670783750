import { useMemo } from "react";
import { ChipTypeMap } from "@mui/material/Chip";
import {
  alpha,
  createTheme,
  PaletteColor,
  PaletteColorOptions,
  Theme,
} from "@mui/material/styles";
import type {} from "@mui/x-date-pickers/themeAugmentation";

import { BREAKPOINTS } from "constants/layout";
import { LIGHT_WARNING } from "constants/palette";
import { DEFAULT_APP_THEME } from "constants/settings";
import {
  borderRadiusByAppTheme,
  buttonBorderRadiusByAppTheme,
  paletteByAppTheme,
  selectionColorByAppTheme,
  typographyByAppTheme,
} from "constants/theming";
import { useAppTheme } from "contexts/useAppThemeContext";
import { AppTheme } from "typings/AppTheme";

interface TypeBorder {
  card: { main: string; hover: string };
  dropZone: string;
}

interface TypeShadow {
  flag: string;
  default: string;
  hover: string;
}

interface TypePrimaryPalette {
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    ai: PaletteColor;
    acme: PaletteColor;
    ascari: PaletteColor;
    bi: PaletteColor;
    dark: PaletteColor;
    driven: PaletteColor;
    light: PaletteColor;
    border: TypeBorder;
    primaryPalette: TypePrimaryPalette;
    icon: {
      verificationStatus: string;
      highlightChip: {
        backgroundColor: string;
        color: string;
      };
    };
    shadow: TypeShadow;
  }

  interface PaletteOptions {
    acme?: PaletteColorOptions;
    ascari?: PaletteColorOptions;
    bi?: PaletteColorOptions;
    dark?: PaletteColorOptions;
    driven?: PaletteColorOptions;
    light?: PaletteColorOptions;
  }

  interface TypeBackground {
    backdrop?: string;
    cardEmptyHeader: string;
    cardLip: string;
    chatBubble: {
      self: string;
      other: string;
    };
    dark: string;
    dividerPattern: string;
    light: string;
    nav: string;
    paperHover: string;
    reminderDateCircle: string;
    timelineConnector: string;
  }

  interface TypeText {
    dark: string;
    light: string;
    ai: string;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    light: true;
    dark: true;
  }
  interface ButtonPropsColorOverrides {
    ai: true;
  }
}

declare module "@mui/material/Alert" {
  interface AlertPropsColorOverrides {
    ai: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    hero: React.CSSProperties;
    body3: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    hero?: React.CSSProperties;
    body3: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    hero: true;
    body3: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    pastel: true;
  }
  interface ChipPropsColorOverrides {
    ai: true;
  }
}

type ChipVariant = ChipTypeMap["props"]["variant"];
type ChipColor = ChipTypeMap["props"]["color"];

const getDesignTokens = (appTheme: AppTheme) => ({
  breakpoints: BREAKPOINTS,
  typography: typographyByAppTheme[appTheme],
  shape: {
    borderRadius: borderRadiusByAppTheme[appTheme],
  },
  palette: paletteByAppTheme[appTheme],
  components: {
    MuiAlert: {
      styleOverrides: {
        action: {
          alignItems: "center",
          padding: 0,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        tag: ({ theme }: { theme: Theme }) => ({
          paddingLeft: theme.spacing(0.5),
        }),
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "*::selection": {
          backgroundColor: selectionColorByAppTheme[appTheme],
          color: paletteByAppTheme[appTheme].text.light,
        },
        "@media print": {
          "footer, nav, header, .no-print": {
            display: "none !important",
          },
          main: {
            paddingLeft: "0 !important",
            paddingTop: "0 !important",
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          ".MuiBackdrop-root": {
            backgroundColor: paletteByAppTheme[appTheme].background.backdrop,
          },
          ".MuiDialogContent-root": {
            padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
          },
          ".MuiDialogActions-root": {
            padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
          },
        }),
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "@media print": {
            display: "none",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: buttonBorderRadiusByAppTheme[appTheme],
          "@media print": {
            display: "none",
          },
        },
        contained: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
          "&:active": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderColor: paletteByAppTheme[appTheme].border.card.main,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: ({ theme }: { theme: Theme }) => ({
          padding: `${theme.spacing(0.25)} ${theme.spacing(0.5)}`,
          fontSize: "0.75rem",
          fontWeight: 600,
        }),
        labelSmall: ({ theme }: { theme: Theme }) => ({
          padding: `0 ${theme.spacing(0.5)}`,
        }),
        iconSmall: ({ theme }: { theme: Theme }) => ({
          margin: 0,
          marginLeft: theme.spacing(-0.25),
        }),
        deleteIconSmall: {
          margin: 0,
        },
      },
      variants: [
        {
          props: {
            variant: "pastel" as ChipVariant,
            color: "default" as ChipColor,
          },
          style: ({ theme }: { theme: Theme }) => ({
            backgroundColor: alpha(
              theme.palette.text.primary,
              theme.palette.mode === "light" ? 0.05 : 0.1,
            ),
            color: theme.palette.text.secondary,
            "&:hover": {
              backgroundColor: alpha(theme.palette.text.primary, 0.25),
            },
          }),
        },
        {
          props: {
            variant: "pastel" as ChipVariant,
            color: "primary" as ChipColor,
          },
          style: ({ theme }: { theme: Theme }) => ({
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
            color:
              theme.palette.primary[
                theme.palette.mode === "light" ? "dark" : "main"
              ],
            "&:hover": {
              backgroundColor: alpha(theme.palette.primary.main, 0.25),
            },
          }),
        },
        {
          props: {
            variant: "pastel" as ChipVariant,
            color: "secondary" as ChipColor,
          },
          style: ({ theme }: { theme: Theme }) => ({
            backgroundColor: alpha(
              theme.palette.secondary.main,
              theme.palette.mode === "light" ? 0.05 : 0.1,
            ),
            color:
              theme.palette.secondary[
                theme.palette.mode === "light" ? "dark" : "main"
              ],
            "&:hover": {
              backgroundColor: alpha(theme.palette.secondary.main, 0.25),
            },
          }),
        },
        {
          props: {
            variant: "pastel" as ChipVariant,
            color: "success" as ChipColor,
          },
          style: ({ theme }: { theme: Theme }) => ({
            backgroundColor: alpha(theme.palette.success.main, 0.1),
            color:
              theme.palette.success[
                theme.palette.mode === "light" ? "dark" : "main"
              ],
            "&:hover": {
              backgroundColor: alpha(theme.palette.success.main, 0.25),
            },
          }),
        },
        {
          props: {
            variant: "pastel" as ChipVariant,
            color: "info" as ChipColor,
          },
          style: ({ theme }: { theme: Theme }) => ({
            backgroundColor: alpha(theme.palette.info.main, 0.1),
            color:
              theme.palette.info[
                theme.palette.mode === "light" ? "dark" : "light"
              ],
            "&:hover": {
              backgroundColor: alpha(theme.palette.info.main, 0.25),
            },
          }),
        },
        {
          props: {
            variant: "pastel" as ChipVariant,
            color: "warning" as ChipColor,
          },
          style: ({ theme }: { theme: Theme }) => ({
            backgroundColor: alpha(theme.palette.warning.main, 0.1),
            color:
              theme.palette.mode === "light"
                ? LIGHT_WARNING[800]
                : theme.palette.warning.main,
            "&:hover": {
              backgroundColor: alpha(theme.palette.warning.main, 0.25),
            },
          }),
        },
        {
          props: {
            variant: "pastel" as ChipVariant,
            color: "error" as ChipColor,
          },
          style: ({ theme }: { theme: Theme }) => ({
            backgroundColor: alpha(theme.palette.error.main, 0.1),
            color:
              theme.palette.error[
                theme.palette.mode === "light" ? "dark" : "light"
              ],
            "&:hover": {
              backgroundColor: alpha(theme.palette.error.main, 0.25),
            },
          }),
        },
        {
          props: {
            variant: "pastel" as ChipVariant,
            color: "ai" as ChipColor,
          },
          style: ({ theme }: { theme: Theme }) => ({
            backgroundColor: alpha(theme.palette.ai.main, 0.1),
            color:
              theme.palette.ai[
                theme.palette.mode === "light" ? "dark" : "light"
              ],
            "&:hover": {
              backgroundColor: alpha(theme.palette.ai.main, 0.25),
            },
          }),
        },
      ],
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
          boxShadow: paletteByAppTheme[appTheme].shadow.default,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          fieldset: {
            transition: `border-color ${theme.transitions.duration.shorter}ms`,
          },
          "@media print": {
            display: "none",
          },
        }),
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          backgroundColor: paletteByAppTheme[appTheme].background.paper,
          borderColor: paletteByAppTheme[appTheme].border.dropZone,
          "@media print": {
            display: "none",
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          hero: "h1",
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        circular: {
          WebkitMaskImage: "-webkit-radial-gradient(white, black)",
        },
      },
    },
  },
});

export const getMuiTheme = (appTheme: AppTheme) =>
  createTheme(getDesignTokens(appTheme));

export const useMuiTheme = () => {
  const { appTheme } = useAppTheme();

  const muiTheme = useMemo(
    () => getMuiTheme(appTheme ?? DEFAULT_APP_THEME),
    [appTheme],
  );

  return { muiTheme };
};
