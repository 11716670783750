export const COLLECTION_ADDED_EVENT = "collection_added";
export const COLLECTION_ID_FIELD = "collection_id";
export const COLLECTOR_ADDED_EVENT = "collector_added";
export const COLLECTOR_ID_FIELD = "collector_id";
export const CONCIERGE_CREATED_EVENT = "concierge_created";
export const CONCIERGE_ID_FIELD = "concierge_id";
export const DEALER_CREATED_EVENT = "dealer_created";
export const DEALER_ID_FIELD = "dealer_id";
export const DENSITY_CHANGED_EVENT = "density_changed";
export const DENSITY_FIELD = "density";
export const HISTORY_RECORD_ADDED_EVENT = "history_record_added";
export const HISTORY_RECORD_ID_FIELD = "history_record_id";
export const LOCATION_ADDED_EVENT = "location_added";
export const LOCATION_ID_FIELD = "location_id";
export const MEDIA_UPLOADED_EVENT = "media_uploaded";
export const SORTING_CHANGED_EVENT = "sorting_changed";
export const SORTING_FIELD = "sorting";
export const THEME_MODE_CHANGED_EVENT = "theme_mode_changed";
export const THEME_MODE_FIELD = "theme_mode";
export const USER_CURATED_ID_FIELD = "curated_id";
export const USER_CURATING_EVENT = "user_curating";
export const USER_EMAIL_CONFIRMED_EVENT = "email_confirmed";
export const USER_ID_FIELD = "user_id";
export const USER_LOGGED_IN_EVENT = "login";
export const USER_LOGGED_OUT_EVENT = "logout";
export const USER_MEMBERSHIP_FIELD = "membership";
export const USER_SET_ID_EVENT = "user_id_set";
export const USER_SET_MEMBERSHIP_EVENT = "user_membership_set";
export const USER_SIGNUP_EVENT = "signup";
export const VEHICLE_ADDED_EVENT = "vehicle_added";
export const VEHICLE_ID_FIELD = "vehicle_id";
