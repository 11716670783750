import { useEffect } from "react";
import { useResolve } from "react-jpex";
import { useSearchParams } from "react-router-dom";

import { DATA_ROOM_TOKEN, TOKEN } from "constants/queryParams";
import type { UserPreferenceService } from "typings/UserPreferenceService";

export const useInviteCode = () => {
  const preferenceService = useResolve<UserPreferenceService>(
    "UserPreferenceService",
  );

  const [params] = useSearchParams();

  useEffect(() => {
    const code = params.get(TOKEN);

    if (code) {
      preferenceService.setCrewInviteCode(code);
    }

    const dataRoomCode = params.get(DATA_ROOM_TOKEN);

    if (dataRoomCode) {
      preferenceService.setDataRoomInviteCode(dataRoomCode);
    }
  }, []);
};
