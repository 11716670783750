export const TYPOGRAPHY = {
  h1: {
    fontSize: "1.5rem",
    lineHeight: 1.2,
    fontWeight: 300,
  },
  h2: {
    fontSize: "1.33rem",
    lineHeight: 1.25,
    fontWeight: 400,
  },
  h3: {
    fontSize: "1.25rem",
    lineHeight: 1.333,
    fontWeight: 400,
  },
  h4: {
    fontSize: "1.2rem",
    lineHeight: 1.5,
    fontWeight: 400,
  },
  h5: {
    fontSize: "1.125rem",
    lineHeight: 1.333,
    fontWeight: 400,
  },
  h6: {
    fontSize: "1rem",
    lineHeight: 1.5,
    fontWeight: 500,
  },
  subtitle1: {
    fontSize: "1rem",
    lineHeight: 1.333,
    fontWeight: 600,
  },
  subtitle2: {
    fontSize: "0.75rem",
    lineHeight: 1.5,
    fontWeight: 400,
  },
  body1: {
    fontSize: "1rem",
    lineHeight: 1.5,
    fontWeight: 400,
  },
  body2: {
    fontSize: "0.875rem",
    lineHeight: 1.5,
    fontWeight: 400,
  },
  body3: {
    fontSize: "0.75rem",
    lineHeight: 1.666,
    fontWeight: 600,
    letterSpacing: "0.25px",
  },
  button: {
    fontSize: "0.875rem",
    fontWeight: 600,
  },
  caption: {
    fontSize: "0.75rem",
    lineHeight: 1.666,
    fontWeight: 400,
  },
};

export const DRIVEN_FONT_FAMILY = [
  `"Source Sans 3 Variable"`,
  `-apple-system`,
  `system-ui`,
  `BlinkMacSystemFont`,
  `"Segoe UI"`,
  `Roboto`,
  `"Helvetica Neue"`,
  `Arial`,
  `sans-serif`,
].join(",");

export const BI_FONT_FAMILY = [
  "Helvetica",
  "Arial",
  "-apple-system",
  "BlinkMacSystemFont",
  "Segoe UI",
  "sans-serif",
].join(",");

export const KESSEL_FONT_FAMILY = [
  "Helvetica",
  "Arial",
  "-apple-system",
  "BlinkMacSystemFont",
  "Segoe UI",
  "sans-serif",
].join(",");

export const ASCARI_FONT_FAMILY = [
  "Roboto",
  "San Francisco",
  "Helvetica",
  "Arial",
  "-apple-system",
  "BlinkMacSystemFont",
  "Segoe UI",
  "sans-serif",
].join(",");
