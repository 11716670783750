import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useAppTheme } from "contexts/useAppThemeContext";
import { AppTheme, validAppThemes } from "typings/AppTheme";

export const useAppThemeQueryParam = () => {
  const { setTheme } = useAppTheme();

  const [searchParams] = useSearchParams();

  const appTheme = searchParams.get("appTheme") as AppTheme;

  useEffect(() => {
    if (appTheme && validAppThemes.includes(appTheme)) {
      setTheme(appTheme);
    }
  }, [appTheme]);
};
