import { createContext, ReactNode, useContext, useState } from "react";

export const sections = [
  "summary",
  "generalInfo",
  "performance",
  "engine",
  "transmission",
  "dimensions",
] as const;

export type Section = (typeof sections)[number];

type SpecsFilterContextProviderProps = {
  children?: ReactNode;
};

type SpecsFilterContextType = {
  filter: Section;
  setFilter: (section: Section) => void;
};

const initialContext: SpecsFilterContextType = {
  filter: "summary",
  setFilter: () => {
    /* noop */
  },
};

const SpecsFilterContext =
  createContext<SpecsFilterContextType>(initialContext);

SpecsFilterContext.displayName = "Driven::SpecsFilter";

export const useSpecsFilter = () => useContext(SpecsFilterContext);

export const SpecsFilterContextProvider = ({
  children,
}: SpecsFilterContextProviderProps) => {
  const [filter, setFilter] = useState<Section>("summary");

  return (
    <SpecsFilterContext.Provider
      value={{
        filter,
        setFilter,
      }}
    >
      {children}
    </SpecsFilterContext.Provider>
  );
};
