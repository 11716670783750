import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useResolve } from "react-jpex";
import { gql, Reference, useMutation } from "@apollo/client";

import { useMessaging } from "contexts/Messaging/useMessagingContext";
import { DataRoom } from "typings/DataRoom";
import { UserPreferenceService } from "typings/UserPreferenceService";

const CONFIRM_DATA_ROOM_GROUP_MEMBERSHIP = gql`
  mutation ConfirmDataRoomMembership($token: String!) {
    confirmDataRoomGroupMembership(token: $token) {
      id
      title
      ownerId
      vehicleId
    }
  }
`;

export const useConfirmDataRoomGroupMembership = () => {
  const userPreferenceService = useResolve<UserPreferenceService>(
    "UserPreferenceService",
  );

  const { t } = useTranslation();
  const { enqueueMessage } = useMessaging();

  const [mutate, { client }] = useMutation<{
    confirmDataRoomMembership: DataRoom;
  }>(CONFIRM_DATA_ROOM_GROUP_MEMBERSHIP);

  const confirm = useCallback(
    async (token?: string | null) => {
      if (!token) return;

      const { data, errors } = await mutate({ variables: { token } });

      userPreferenceService.setDataRoomInviteCode(undefined);

      if (errors?.[0]) {
        enqueueMessage({
          text: t(errors[0].message),
          variant: "error",
          persist: true,
        });
      }

      if (data?.confirmDataRoomMembership) {
        client.cache.modify<{ dataRoomsAsMember: Reference[] }>({
          fields: {
            dataRoomsAsMember: (existingVehicles, { toReference }) => [
              toReference(data.confirmDataRoomMembership) as Reference,
              ...existingVehicles,
            ],
          },
        });
        enqueueMessage({
          text: t("notification.dataRoom.group.membership.accepted.title"),
          variant: "success",
        });
      }
    },
    [mutate, enqueueMessage, t],
  );

  return { confirm };
};
