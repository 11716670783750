import {
  faEnvelope,
  faFingerprint,
  faMobileButton,
} from "@fortawesome/pro-light-svg-icons";

import { MfaOptions } from "typings/Mfa";

export const MFA_METHOD_OPTIONS: MfaOptions = {
  otp: {
    icon: faMobileButton,
    claimKey: "confirmMfaAuthAppClaim",
    cantAccessKey: "mfaCantAccessAuthApp",
    useKey: "mfaUseAuthApp",
  },
  email: {
    icon: faEnvelope,
    claimKey: "confirmMfaEmailClaim",
    cantAccessKey: "mfaCantAccessEmail",
    useKey: "mfaUseEmail",
  },
  webauthn: {
    icon: faFingerprint,
    claimKey: "confirmMfaWebAuthnClaim",
    cantAccessKey: "mfaCantAccessWebAuthn",
    useKey: "mfaUseWebAuthn",
  },
};
