import { Collector } from "typings/Collector";

type Subject = {
  id?: string | null;
  email?: string;
};

const registerConsent = (
  subject: Subject,
  preferences: Record<string, boolean>,
  proofs: unknown,
) => {
  if (window?._iub) {
    window._iub.cons_instructions.push([
      "submit",
      {
        writeOnLocalStorage: false,
        consent: {
          subject,
          preferences,
          legal_notices: [
            {
              identifier: "privacy_policy",
            },
            {
              identifier: "cookie_policy",
            },
            {
              identifier: "terms",
            },
          ],
          proofs,
        },
      },
      {
        success() {
          /* noop */
        },
        error() {
          /* noop */
        },
      },
    ]);
  }
};

export const registerSignUpConsent = (user: Partial<Collector>) => {
  registerConsent(
    { id: user.id, email: user.email ?? "" },
    {
      consent: true,
      signUp: true,
    },
    [
      {
        content: JSON.stringify({
          id: user.id,
          email: user.email,
          username: user.username,
        }),
      },
    ],
  );
};

export const registerNewsletterConsent = (
  id: string | null | undefined,
  email: string,
) => {
  registerConsent({ id, email }, { consent: true, newsletter: true }, [
    {
      content: JSON.stringify({
        id,
        email,
      }),
    },
  ]);
};
