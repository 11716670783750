import { SuspenseWrapper } from "components/Layout/SuspenseWrapper";
import { ServiceModal } from "components/Service/ServiceModal";
import { useModalWithSubscription } from "hooks/useModalWithSubscription";
import { Service } from "typings/Service";
import { DialogTopics, ServiceTopics } from "typings/Topics";

export const ServiceDialogs = () => {
  const { object: toEdit, handleClose: handleCloseServiceEdit } =
    useModalWithSubscription<Service>(
      DialogTopics.EditService,
      ServiceTopics.Updated,
    );

  return (
    <div data-testid="service-dialogs">
      {!!toEdit && (
        <SuspenseWrapper>
          <ServiceModal service={toEdit} onClose={handleCloseServiceEdit} />
        </SuspenseWrapper>
      )}
    </div>
  );
};
