import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useMessaging } from "contexts/Messaging/useMessagingContext";
import { AppTopics } from "typings/Topics";

export const useError = () => {
  const { t } = useTranslation();
  const { enqueueMessage } = useMessaging();

  useEffect(() => {
    const sub = PubSub.subscribe(AppTopics.Error, (_, message: string) => {
      enqueueMessage({
        text: t(message),
        variant: "error",
      });
    });

    return () => {
      PubSub.unsubscribe(sub);
    };
  }, [enqueueMessage]);
};
