import { ApolloClient, gql } from "@apollo/client";
import { setUser } from "@sentry/react";
import { Buffer } from "buffer";

import {
  COLLECTOR_PROFILE_FIELDS,
  MEMBERSHIP_FRAGMENT,
  QUOTA_FRAGMENT,
  SUBSCRIPTION_FRAGMENT,
  TIER_FRAGMENT,
  USER_ENCRYPTION_FIELDS,
  USER_FIELDS,
} from "constants/fragments";
import { Collector } from "typings/Collector";
import { CollectorService } from "typings/CollectorService";
import { filterEmptyProperties } from "utils/filterEmptyProperties";

export const FETCH_DETAILS = gql`
  ${COLLECTOR_PROFILE_FIELDS}
  ${MEMBERSHIP_FRAGMENT}
  ${USER_FIELDS}
  ${USER_ENCRYPTION_FIELDS}
  ${QUOTA_FRAGMENT}
  ${TIER_FRAGMENT}
  ${SUBSCRIPTION_FRAGMENT}
  query FetchWithPrivateData($id: ID!) {
    collector(id: $id) {
      ...UserFields
      ...UserEncryptionFields
      ...CollectorProfileFields
      confirmed
      managerOfCrews {
        ...MembershipFragment
        token
      }
      memberOfCrews {
        ...MembershipFragment
      }
      organizationsIds
      concierges {
        ...CollectorProfileFields
      }
      quota {
        ...QuotaFragment
      }
      tier {
        ...TierFragment
      }
      tierExpirationDate
      paywallDisabled
      externalId
      subscriptions {
        ...SubscriptionFragment
      }
      enabledExperimentalFeatures
    }
  }
`;

const RESEND_CONFIRM_EMAIL = gql`
  mutation ResendConfirmEmail($email: String) {
    resendConfirmEmail(email: $email) {
      void
    }
  }
`;

const SET_PROFILE_PICTURE = gql`
  ${COLLECTOR_PROFILE_FIELDS}
  mutation SetProfilePicture($profilePictureId: ID!) {
    setProfilePicture(profilePictureId: $profilePictureId) {
      ...CollectorProfileFields
    }
  }
`;

const SET_USER_MARKETING = gql`
  mutation SetUserMarketing($marketing: String) {
    setUserMarketing(marketing: $marketing) {
      id
      marketing
    }
  }
`;

const SET_USER_MEMBERSHIP = gql`
  mutation SetUserMembership($membership: String) {
    setUserMembership(membership: $membership) {
      id
      membership
    }
  }
`;

const FETCH_COLLECTOR = gql`
  ${COLLECTOR_PROFILE_FIELDS}
  query FetchCollector($id: ID!) {
    collector(id: $id) {
      ...CollectorProfileFields
    }
  }
`;

export default class ApolloCollectorService implements CollectorService {
  constructor(private apolloClient: ApolloClient<any>) {}

  async fetch(id: Collector["id"]): Promise<Collector> {
    const { data, errors } = await this.apolloClient.query<{
      collector: Collector;
    }>({
      query: FETCH_COLLECTOR,
      variables: { id },
    });

    if (errors) {
      throw errors[0];
    }

    return data.collector;
  }

  async resendConfirmEmail(): Promise<void> {
    const { errors } = await this.apolloClient.mutate({
      mutation: RESEND_CONFIRM_EMAIL,
    });

    if (errors) {
      throw errors[0];
    }
  }

  async fetchWithPrivateData(id: Collector["id"]): Promise<Collector> {
    const { data, error } = await this.apolloClient.query<{
      collector: Collector;
    }>({
      query: FETCH_DETAILS,
      variables: { id },
    });

    if (error) {
      throw error;
    }

    setUser({ id: data.collector.id });

    return data.collector;
  }

  async setMarketing(marketing: Collector["marketing"]): Promise<void> {
    const { errors } = await this.apolloClient.mutate({
      mutation: SET_USER_MARKETING,
      variables: {
        marketing: Buffer.from(
          JSON.stringify(filterEmptyProperties(marketing ?? {})),
        ).toString("base64"),
      },
    });

    if (errors) {
      throw errors[0];
    }
  }

  async setMembership(membership: Collector["membership"]): Promise<void> {
    const { errors } = await this.apolloClient.mutate({
      mutation: SET_USER_MEMBERSHIP,
      variables: { membership },
    });

    if (errors) {
      throw errors[0];
    }
  }

  async setProfilePicture(
    thumbnail: Collector["profilePicture"],
  ): Promise<void> {
    if (thumbnail?.id) {
      const { errors } = await this.apolloClient.mutate({
        mutation: SET_PROFILE_PICTURE,
        variables: { profilePictureId: thumbnail.id },
      });

      if (errors) {
        throw errors[0];
      }
    }
  }

  async changeEmail(email: Collector["email"]): Promise<void> {
    const { errors } = await this.apolloClient.mutate({
      mutation: RESEND_CONFIRM_EMAIL,
      variables: { email },
    });

    if (errors) {
      throw errors[0];
    }
  }
}
