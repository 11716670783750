import { lazy } from "react";

import { SuspenseWrapper } from "components/Layout/SuspenseWrapper";
import { VaultRecoverPayload } from "components/Vault/dialogs/RecoverMediaDialog";
import { useModalWithSubscription } from "hooks/useModalWithSubscription";
import { Media } from "typings/Media";
import { DialogTopics, VehicleTopics } from "typings/Topics";

const EditMediaDialog = lazy(() => import("components/Vault/EditMediaDialog"));
const AssignToHistoryRecordDialog = lazy(
  () => import("components/Vault/AssignToHistoryRecordDialog"),
);
const AssignToVehicleDialog = lazy(
  () => import("components/Vault/AssignToVehicleDialog"),
);
const RecoverMediaDialog = lazy(
  () => import("components/Vault/dialogs/RecoverMediaDialog"),
);

export const VaultDialogs = () => {
  const { object: toEdit, handleClose: handleCloseEdit } =
    useModalWithSubscription<Media>(
      DialogTopics.VaultEditMedia,
      VehicleTopics.MediaUpdated,
    );

  const {
    object: toAssignToHistoryRecord,
    handleClose: handleCloseAssignToHistoryRecord,
  } = useModalWithSubscription<Media>(
    DialogTopics.VaultAssignToHistoryRecord,
    VehicleTopics.MediaUpdated,
  );

  const { object: toAssignVehicle, handleClose: handleCloseAssignVehicle } =
    useModalWithSubscription<Media>(
      DialogTopics.VaultAssignToVehicle,
      VehicleTopics.MediaUpdated,
    );

  const { object: toRecoverMedia, handleClose: handleCloseRecoverMedia } =
    useModalWithSubscription<VaultRecoverPayload>(
      DialogTopics.VaultRecoverMedia,
    );

  return (
    <div data-testid="vault-dialogs">
      <>
        {!!toEdit && (
          <SuspenseWrapper>
            <EditMediaDialog media={toEdit} onClose={handleCloseEdit} />
          </SuspenseWrapper>
        )}

        {!!toAssignToHistoryRecord && (
          <SuspenseWrapper>
            <AssignToHistoryRecordDialog
              media={toAssignToHistoryRecord}
              onClose={handleCloseAssignToHistoryRecord}
            />
          </SuspenseWrapper>
        )}

        {!!toAssignVehicle && (
          <SuspenseWrapper>
            <AssignToVehicleDialog
              media={toAssignVehicle}
              onClose={handleCloseAssignVehicle}
            />
          </SuspenseWrapper>
        )}

        {!!toRecoverMedia && (
          <SuspenseWrapper>
            <RecoverMediaDialog
              {...toRecoverMedia}
              onClose={handleCloseRecoverMedia}
            />
          </SuspenseWrapper>
        )}
      </>
    </div>
  );
};
