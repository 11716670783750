import { useModalWithSubscription } from "hooks/useModalWithSubscription";
import { UserTopics } from "typings/Topics";

export const useOverQuotaModal = () => {
  const { object, handleClose } = useModalWithSubscription(
    UserTopics.OverQuota,
  );

  return { overQuotaModalRef: object, handleClose };
};
