import Box, { BoxProps } from "@mui/material/Box";
import styled from "@mui/system/styled";

export const IconWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.action.hover,
  width: theme.spacing(8),
  height: theme.spacing(8),
  borderRadius: "50%",
}));
