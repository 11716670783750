import { useCallback, useEffect, useState } from "react";

export function useModalWithSubscription<T = unknown>(
  openTopic: string,
  closeTopic?: string,
) {
  const [object, setObject] = useState<T | null>(null);

  const handleClose = useCallback(() => {
    setObject(null);
  }, [setObject]);

  useEffect(() => {
    const onOpen = PubSub.subscribe(openTopic, (_msg, data: T) => {
      setObject(data ?? ({} as T));
    });

    let onClose: string;

    if (closeTopic) onClose = PubSub.subscribe(closeTopic, handleClose);

    return () => {
      PubSub.unsubscribe(onOpen);
      if (onClose) PubSub.unsubscribe(onClose);
    };
  }, []);

  return {
    object,
    open: !!object,
    handleClose,
  };
}
