import { faClose } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";

type DialogCloseButtonProps = IconButtonProps & {
  onClose: () => void;
};

export const DialogCloseButton = ({
  onClose,
  sx,
  ...rest
}: DialogCloseButtonProps) => {
  const theme = useTheme();

  return (
    <IconButton
      aria-label="close"
      data-testid="dialog-close-button"
      sx={{
        width: theme.spacing(4),
        height: theme.spacing(4),
        color: "primary.main",
        ...sx,
      }}
      onClick={onClose}
      {...rest}
    >
      <FontAwesomeIcon icon={faClose} size="xs" />
    </IconButton>
  );
};
