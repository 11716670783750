export const Density = {
  GRID: "grid",
  LIST: "list",
  MAP: "map",
  MINIMAL: "minimal",
  TIMELINE: "timeline",
} as const;

export type Density = (typeof Density)[keyof typeof Density];

export const validDensities: Density[] = Object.values(Density);

export const DensityScope = {
  COLLECTION: "collection",
  LISTING: "listing",
  LOCATION: "location",
  LOGBOOK: "logbook",
  VEHICLE: "vehicle",
} as const;

export type DensityScope = (typeof DensityScope)[keyof typeof DensityScope];

export type DensitySettings = Record<DensityScope, Density>;
