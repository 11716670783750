import { lazy } from "react";
import { Route } from "react-router-dom";

import { Layout } from "components/Layout/Layout";
import { MAINTENANCE } from "constants/routes";
import { maintenanceEnabled } from "utils/featureFlags";

const MaintenancePage = lazy(() => import("pages/maintenance/MaintenancePage"));
const PublicAppBar = lazy(() => import("components/AppBar/PublicAppBar"));

export default function MaintenanceRoutes() {
  return maintenanceEnabled ? (
    <Route element={<Layout AppBar={PublicAppBar} />}>
      <Route
        key={MAINTENANCE}
        element={<MaintenancePage />}
        path={MAINTENANCE}
      />
    </Route>
  ) : null;
}
