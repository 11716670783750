import { gql } from "@apollo/client";

export const USER_FIELDS = gql`
  fragment UserFields on UserFieldsInterfaceType {
    id
    currency
    email
    language
    isMfaEnabled
    mfaEnabledMethods
    mfas {
      method
      credentials
    }
    name
    organizationsIds
    preferredMfaEnabledMethod
    role
    surname
    username
  }
`;

export const USER_ENCRYPTION_FIELDS = gql`
  fragment UserEncryptionFields on UserFieldsInterfaceType {
    dek
    iv
    privateKey
    publicKey
    salt
  }
`;

export const TAG_FIELDS = gql`
  fragment TagFields on Tag {
    id
    name
    system
  }
`;

export const IMAGE_FIELDS = gql`
  fragment ImageFields on Media {
    id
    filename
    historyRecordId
    isReadOnly
    isSensitive
    iv
    mimeType
    url
  }
`;

export const MEDIA_FIELDS = gql`
  fragment MediaFields on Media {
    id
    bytesSize
    filename
    historyRecordId
    isReadOnly
    isSensitive
    iv
    mimeType
    ownerId
    resolvedInDataRoom
    uploadedAt
    url
    vehicleId
    tags {
      id
    }
  }
`;

export const COLLECTOR_BASE_FIELDS = gql`
  fragment CollectorBaseFields on Collector {
    id
    currency
    email
    language
    name
    organizationsIds
    role
    surname
    username
  }
`;

export const COLLECTOR_PROFILE_FIELDS = gql`
  fragment CollectorProfileFields on Collector {
    id
    appTheme
    bio
    currency
    email
    enabledExperimentalFeatures
    isActive
    language
    name
    organizationsIds
    profilePicture {
      id
      url
    }
    registeredAt
    role
    socialLinks
    surname
    username
  }
`;

export const MEMBERSHIP_FRAGMENT = gql`
  ${COLLECTOR_PROFILE_FIELDS}
  fragment MembershipFragment on CrewMembership {
    id
    manager {
      ...CollectorProfileFields
    }
    member {
      ...CollectorProfileFields
      isMfaEnabled
    }
    role
    createdAt
  }
`;

export const VEHICLE_CORE_FIELDS = gql`
  fragment VehicleCoreFields on Vehicle {
    id
    description
    distanceUnit
    isReadOnly
    make
    model
    month
    organizationId
    ownerId
    type
    variant
    status
    year
  }
`;

export const VEHICLE_DETAIL_FIELDS = gql`
  ${VEHICLE_CORE_FIELDS}
  ${IMAGE_FIELDS}
  fragment VehicleDetailFields on Vehicle {
    ...VehicleCoreFields
    mediasCount
    mainMedia {
      ...ImageFields
    }
    tags
  }
`;

export const VEHICLE_CARD_FIELDS = gql`
  ${VEHICLE_DETAIL_FIELDS}
  ${IMAGE_FIELDS}
  fragment VehicleCardFields on Vehicle {
    ...VehicleDetailFields
    collectionsIds
    consignmentId
    consignment {
      id
      address
      organizationId
      title
      type
    }
    locationId
    location {
      id
      address
      ownerId
      title
      type
    }
    mainMedia {
      ...ImageFields
    }
    mediasCount
    ownerId
    uploadedAt
    mileageRecord: mileage {
      id
      distance
    }
    valuationRecord: valuation {
      id
      price
    }
  }
`;

export const VEHICLE_CARD_SPECS_FIELDS = gql`
  ${VEHICLE_CARD_FIELDS}
  fragment VehicleCardSpecsFields on Vehicle {
    ...VehicleCardFields
    organizationId
    specs
    gptValuation {
      date
      max
      min
    }
    purchasePricePoint {
      date
      note
      price
    }
    salePricePoint {
      date
      note
      price
    }
    insurancePricePoint {
      date
      note
      price
    }
    taxablePricePoint {
      date
      note
      price
    }
    technicalData
    vehicleData
  }
`;

export const HISTORY_RECORD_FIELDS = gql`
  ${IMAGE_FIELDS}
  fragment HistoryRecordFields on HistoryRecord {
    id
    address
    createdAt
    date
    description
    distance
    draft
    highlight
    mainMedia {
      ...ImageFields
    }
    mediasCount
    ownerId
    price
    resolvedInDataRoom
    title
    type
    vehicleId
  }
`;

export const DOCUMENT_FIELDS = gql`
  ${MEDIA_FIELDS}
  ${TAG_FIELDS}
  fragment DocumentFields on Media {
    ...MediaFields
    tags {
      ...TagFields
    }
  }
`;

export const VAULT_DOCUMENT_FIELDS = gql`
  ${MEDIA_FIELDS}
  ${TAG_FIELDS}
  fragment VaultDocumentFields on Media {
    ...MediaFields
    deletedAt
    iv
    historyRecord {
      id
      ownerId
      title
      type
      vehicleId
    }
    serviceRequest {
      id
      sequentialNumber
      ownerId
      vehicleId
    }
    tags {
      ...TagFields
    }
    textDetections {
      id
      message
      status
    }
  }
`;

export const HISTORY_RECORD_FIELDS_WITH_VEHICLE = gql`
  ${HISTORY_RECORD_FIELDS}
  ${VEHICLE_DETAIL_FIELDS}
  fragment HistoryRecordFieldsWithVehicle on HistoryRecord {
    ...HistoryRecordFields
    vehicle {
      ...VehicleDetailFields
    }
  }
`;

export const VEHICLE_LOG_BOOK_FIELDS = gql`
  ${HISTORY_RECORD_FIELDS}
  fragment VehicleLogbookFields on Vehicle {
    historyRecords {
      ...HistoryRecordFields
    }
    mileageRecord: mileage {
      ...HistoryRecordFields
    }
    valuationRecord: valuation {
      ...HistoryRecordFields
    }
  }
`;

export const COLLECTION_BASIC_FIELDS = gql`
  fragment CollectionBasicFields on CollectionFieldsInterfaceType {
    id
    ownerId
    profilePicture {
      id
      url
    }
    vehicleCount
    vehiclesIds
    title
  }
`;

export const COLLECTION_FIELDS = gql`
  ${COLLECTION_BASIC_FIELDS}
  ${IMAGE_FIELDS}
  fragment CollectionFields on CollectionFieldsInterfaceType {
    ...CollectionBasicFields
    coverPicture {
      ...ImageFields
    }
    description
  }
`;

export const LOCATION_BASIC_FIELDS = gql`
  ${COLLECTION_BASIC_FIELDS}
  fragment LocationBasicFields on Location {
    ...CollectionBasicFields
    address
    organizationId
    type
    walkThrough
  }
`;

export const LOCATION_FIELDS = gql`
  ${IMAGE_FIELDS}
  ${LOCATION_BASIC_FIELDS}
  fragment LocationFields on Location {
    ...LocationBasicFields
    address
    coverPicture {
      ...ImageFields
    }
    description
    mediasCount
    profilePicture {
      ...ImageFields
    }
  }
`;

export const LIST_VIEW_FIELDS = gql`
  fragment ListViewFields on ListView {
    id
    columns
    title
  }
`;

export const TIER_FRAGMENT = gql`
  fragment TierFragment on Tier {
    id
    externalId
    maxListings
    maxSeats
    maxStorage
    maxVehicles
    title
  }
`;

export const QUOTA_FRAGMENT = gql`
  fragment QuotaFragment on UserQuota {
    id
    crewCount
    listingCount
    mediaSize
    organizationId
    vaultSize
    vehicleCount
  }
`;

export const ACCESS_LEVELS_FRAGMENT = gql`
  fragment AccessLevelsFragment on NegotiationAccessLevels {
    externalLinks
    gallery
    specs
    vault
  }
`;

export const NEGOTIATION_MESSAGE_FRAGMENT = gql`
  fragment NegotiationMessageFragment on NegotiationMessage {
    id
    body
    createdAt
    role
  }
`;

export const NEGOTIATION_FRAGMENT = gql`
  fragment NegotiationFragment on Negotiation {
    id
    role
    status
    createdAt
    updatedAt
  }
`;

export const WISHLIST_FRAGMENT = gql`
  ${IMAGE_FIELDS}
  fragment WishlistFragment on Wishlist {
    id
    description
    gptValuation {
      date
      max
      min
    }
    mainMedia {
      ...ImageFields
    }
    make
    maxPrice
    minPrice
    model
    ownerId
    specs
    type
    variant
    vehicleData
    year
  }
`;

export const LISTING_FRAGMENT = gql`
  ${IMAGE_FIELDS}
  ${NEGOTIATION_FRAGMENT}
  fragment ListingFragment on Listing {
    id
    condition
    countryCode
    cover {
      ...ImageFields
    }
    createdAt
    currentNegotiation {
      ...NegotiationFragment
    }
    description
    editable
    mainMedia {
      ...ImageFields
    }
    make
    mediasCount
    model
    odometer: distance
    price
    status
    type
    updatedAt
    variant
    vehicleId
    year
  }
`;

export const DISCOUNT_FRAGMENT = gql`
  fragment DiscountFragment on Discount {
    coupon {
      id
      name
      percentOff
    }
    promotionCode {
      id
      code
      active
      expiresAt
    }
  }
`;

export const SUBSCRIPTION_FRAGMENT = gql`
  ${DISCOUNT_FRAGMENT}
  fragment SubscriptionFragment on SubscriptionType {
    creditCard {
      brand
      expiryMonth
      expiryYear
      last4
    }
    discount {
      ...DiscountFragment
    }
    interval
    isRenewed
    productId
    status
  }
`;

export const SERVICE_REQUEST_MESSAGE_FRAGMENT = gql`
  fragment ServiceRequestMessageFragment on ServiceRequestMessage {
    id
    createdAt
    body
    layout
    ownerId
    owner {
      id
      name
      organizationsIds
      profilePicture {
        id
        url
      }
      role
      surname
      username
    }
    payload
    serviceRequestId
    updatedAt
  }
`;

export const SERVICE_REQUEST_FRAGMENT = gql`
  ${SERVICE_REQUEST_MESSAGE_FRAGMENT}
  ${VEHICLE_CORE_FIELDS}
  fragment ServiceRequestFragment on ServiceRequest {
    id
    assignedToId
    createdAt
    details
    messages {
      ...ServiceRequestMessageFragment
    }
    organizationId
    ownerId
    owner {
      id
      name
      organizationsIds
      surname
      username
    }
    sequentialNumber
    status
    updatedAt
    vehicle {
      ...VehicleCoreFields
    }
  }
`;

export const DATA_ROOM_FIELDS = gql`
  ${VEHICLE_CORE_FIELDS}
  fragment DataRoomFields on DataRoom {
    id
    title
    ownerId
    vehicleId
    groups {
      id
      dataRoomId
      ownerId
      title
      memberships {
        id
        createdAt
        memberId
        email
        token
        member {
          id
          email
          name
          surname
          username
        }
      }
      logBook {
        id
      }
      vault {
        id
      }
    }
    vehicle {
      ...VehicleCoreFields
      resolvedInDataRoom
      historyRecords {
        id
        date
        ownerId
        type
        title
        vehicleId
      }
      vaultDocuments {
        id
        filename
        historyRecordId
        iv
        mimeType
        uploadedAt
      }
    }
  }
`;

export const DATA_ROOM_MESSAGE_FRAGMENT = gql`
  fragment DataRoomMessageFragment on DataRoomMessage {
    id
    createdAt
    dataRoomId
    memberId
    member {
      id
      name
      profilePicture {
        id
        url
      }
      surname
      username
    }
    message
    owner {
      id
      name
      organizationsIds
      profilePicture {
        id
        url
      }
      role
      surname
      username
    }
  }
`;
