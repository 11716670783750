import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid2";

import { FormDialog } from "components/forms/FormDialog";
import { SERVICE_FORM } from "constants/forms";
import { Service } from "typings/Service";

import { ServiceForm } from "./ServiceForm";
import { useServiceForm } from "./useServiceForm";

type ServiceModalProps = {
  onClose: () => void;
  service?: Service;
};

export const ServiceModal = ({ onClose, service }: ServiceModalProps) => {
  const { t } = useTranslation();

  const { form, handleSubmit } = useServiceForm(service);

  return (
    <FormDialog
      open
      form={form}
      formId={SERVICE_FORM}
      maxWidth="sm"
      title={t("dialog.service.manage.title")}
      onClose={onClose}
      onSubmit={handleSubmit}
    >
      <Grid container mb={2} spacing={4}>
        <ServiceForm />
      </Grid>
    </FormDialog>
  );
};
