export function filterEmptyProperties(obj: Record<string, unknown | null>) {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([_, value]) =>
        value !== undefined &&
        ((typeof value === "string" && value !== "") ||
          (Array.isArray(value) && value.length > 0) ||
          (typeof value === "number" && !Number.isNaN(value)) ||
          typeof value === "boolean"),
    ),
  );
}
