import {
  ApolloClient,
  createHttpLink,
  gql,
  InMemoryCache,
} from "@apollo/client";
import fetch from "cross-fetch";

import { AuthenticationService } from "typings/AuthenticationService";
import { UserTopics } from "typings/Topics";

const REFRESH = gql`
  mutation Refresh($token: String!) {
    refresh(token: $token) {
      accessToken
      refreshToken
    }
  }
`;
const { REACT_APP_API_URL } = import.meta.env;

const uri = new URL(
  "/graphql",
  REACT_APP_API_URL ?? "http://localhost:3000",
).toString();

const client = new ApolloClient({
  link: createHttpLink({
    uri,
    fetch: (...args) => fetch(...args),
  }),
  cache: new InMemoryCache(),
  credentials: "include",
});

export class RefreshTokenService {
  constructor(private authenticationService: AuthenticationService) {}

  isRefreshing = false;

  async refreshTokens() {
    const token = this.authenticationService.getRefreshToken();

    if (!token) throw new Error("No refresh token found");

    this.isRefreshing = true;

    try {
      const { data } = await client.mutate({
        mutation: REFRESH,
        variables: { token },
      });

      const { refreshToken, accessToken } = data.refresh;

      this.authenticationService.setAccessToken(accessToken);
      this.authenticationService.setRefreshToken(refreshToken);

      PubSub.publish(UserTopics.TokenRefreshed);
    } catch (error) {
      this.authenticationService.setAccessToken(undefined);
      this.authenticationService.setRefreshToken(undefined);

      PubSub.publish(UserTopics.Unauthorized);
    } finally {
      this.isRefreshing = false;
    }
  }
}
