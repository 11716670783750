import i18n from "i18n/config";

export const Organizations = {
  ACME: "acme",
  ASCARI: "ascari",
  BI: "bi",
  CURATED: "curated",
  DRIVEN: "driven",
  ILR: "ilr",
  KESSEL: "kessel",
} as const;

export type Organizations = (typeof Organizations)[keyof typeof Organizations];

export const validOrganizations: Organizations[] = Object.values(Organizations);

export const translatedOrganizations = {
  [Organizations.ACME]: i18n.t("organization.acme"),
  [Organizations.ASCARI]: i18n.t("organization.ascari"),
  [Organizations.BI]: i18n.t("organization.bi"),
  [Organizations.CURATED]: i18n.t("organization.curated"),
  [Organizations.DRIVEN]: i18n.t("organization.driven"),
  [Organizations.ILR]: i18n.t("organization.ilr"),
  [Organizations.KESSEL]: i18n.t("organization.kessel"),
};

export type Organization = {
  id: Organizations;
  title: string;
};

export const DefaultOrganization: Organizations = Organizations.DRIVEN;

export const isRelatedToOrganization = (
  organizationId: Organizations | null | undefined,
  organizationsIds: Organizations[] | null | undefined,
) =>
  !!organizationId &&
  !!organizationsIds &&
  !isDefaultOrganization(organizationId) &&
  organizationsIds.includes(organizationId);

export const isDefaultOrganization = (
  organizationId: Organizations | null | undefined,
) => !!organizationId && organizationId === DefaultOrganization;

export const isNotDefaultOrganization = (
  organizationId: Organizations | null | undefined,
) => !!organizationId && organizationId !== DefaultOrganization;

/** checks if list contains organizations besides default */
export const hasExternalPartner = (
  organizationsIds: Organizations[] | null | undefined,
) =>
  Array.isArray(organizationsIds) &&
  organizationsIds.filter((id) => id !== DefaultOrganization).length > 0;

/** prefer non-default org */
export const getPrimaryOrganization = (
  organizationsIds: Organizations[] | null | undefined,
): Organizations => {
  if (!Array.isArray(organizationsIds)) {
    return DefaultOrganization;
  }

  const external = organizationsIds.find((id) => id !== DefaultOrganization);

  return external ?? DefaultOrganization;
};
