import { lazy, useEffect } from "react";

import { SuspenseWrapper } from "components/Layout/SuspenseWrapper";
import { useAuthenticatedUser } from "contexts/useAuthenticatedUserContext";
import { useEncryptionWorker } from "hooks/useEncryptionWorker";
import { useModalWithSubscription } from "hooks/useModalWithSubscription";
import { Media } from "typings/Media";
import {
  CollectorTopics,
  DialogTopics,
  EncryptionTopics,
} from "typings/Topics";

const PassphraseDialog = lazy(
  () => import("pages/settings/encryption/PassphraseDialog"),
);
const PassphraseRequiredDialog = lazy(
  () => import("components/dialogs/PassphraseRequiredDialog"),
);

type TopicData = {
  topic: EncryptionTopics;
  mediaId?: Media["id"] | Array<Media["id"]>;
};

export const EncryptionDialogs = () => {
  const encryptionWorker = useEncryptionWorker();
  const { publicKey } = useAuthenticatedUser();

  const { object, handleClose: handleCloseEditPassphrase } =
    useModalWithSubscription(
      DialogTopics.EditPassphrase,
      CollectorTopics.Updated,
    );

  const { object: callback, handleClose: handleClosePassphraseRequired } =
    useModalWithSubscription<TopicData>(EncryptionTopics.PassphraseRequired);

  useEffect(() => {
    const required = PubSub.subscribe(
      EncryptionTopics.DataEncryptionKeyRequired,
      async (_message, callback: TopicData) => {
        if (!publicKey) {
          PubSub.publish(DialogTopics.EditPassphrase);
          return;
        }

        const isDEKAvailable = await encryptionWorker.isDEKAvailable();

        if (isDEKAvailable) {
          PubSub.publish(callback.topic, callback.mediaId);
        } else {
          PubSub.publish(EncryptionTopics.PassphraseRequired, callback);
        }
      },
    );

    return () => {
      PubSub.unsubscribe(required);
    };
  }, [encryptionWorker, publicKey]);

  return (
    <>
      {object ? (
        <SuspenseWrapper>
          <PassphraseDialog onClose={handleCloseEditPassphrase} />
        </SuspenseWrapper>
      ) : null}

      {callback ? (
        <SuspenseWrapper>
          <PassphraseRequiredDialog
            callback={callback}
            onClose={handleClosePassphraseRequired}
          />
        </SuspenseWrapper>
      ) : null}
    </>
  );
};
