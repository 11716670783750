import { Outlet } from "react-router-dom";

import { CollectionDialogs } from "components/Collection/dialogs/CollectionDialogs";
import { CollectorDialogs } from "components/Collector/dialogs/CollectorDialogs";
import DataRoomDialogs from "components/DataRoom/dialogs/DataRoomDialogs";
import { EncryptionDialogs } from "components/dialogs/EncryptionDialogs";
import HistoryRecordDialogs from "components/HistoryRecord/dialogs/HistoryRecordDialogs";
import { SuspenseWrapper } from "components/Layout/SuspenseWrapper";
import { LocationDialogs } from "components/Location/dialogs/LocationDialogs";
import ReminderDialogs from "components/Reminder/dialogs/ReminderDialogs";
import { ServiceDialogs } from "components/Service/ServiceDialogs";
import RequestDialogs from "components/ServiceRequest/dialogs/RequestDialogs";
import { VaultDialogs } from "components/Vault/VaultDialogs";
import { VehicleDialogs } from "components/Vehicle/dialogs/VehicleDialogs";

export const Dialogs = () => (
  <>
    <SuspenseWrapper>
      <Outlet />
    </SuspenseWrapper>
    <CollectionDialogs />
    <CollectorDialogs />
    <DataRoomDialogs />
    <EncryptionDialogs />
    <HistoryRecordDialogs />
    <LocationDialogs />
    <ReminderDialogs />
    <RequestDialogs />
    <ServiceDialogs />
    <VaultDialogs />
    <VehicleDialogs />
  </>
);
