/* eslint-disable no-console */
import {
  ApolloClient,
  ApolloLink,
  FetchResult,
  from,
  Observable,
} from "@apollo/client";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import {
  captureException,
  captureMessage,
  setTag,
  withScope,
} from "@sentry/react";
import { SentryLink } from "apollo-link-sentry";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import fetchForMSW from "cross-fetch";
import { GraphQLError, GraphQLFormattedError } from "graphql";
import { v4 } from "uuid";

import {
  CUSTOM_LOGIN_ERROR_MESSAGE,
  OVER_QUOTA_ERROR_MESSAGE,
  VEHICLE_ARCHIVED_ERROR_MESSAGE,
} from "constants/errorKeys";
import { inMemoryCache } from "services/ApolloCacheProvider";
import { RefreshTokenService } from "services/RefreshTokenService";
import { AuthenticationService } from "typings/AuthenticationService";
import { AppTopics, UserTopics } from "typings/Topics";
import { isErrorWithMessage } from "utils/isErrorWithMessage";
import { promiseToObservable } from "utils/promiseToObservable";

const { REACT_APP_SENTRY_ENVIRONMENT, REACT_APP_API_URL } = import.meta.env;

const isDevelopment =
  import.meta.env.REACT_APP_SENTRY_ENVIRONMENT === "development";

if (isDevelopment) {
  loadDevMessages();
  loadErrorMessages();
}

const uri = new URL(
  "/graphql",
  REACT_APP_API_URL ?? "http://localhost:3000",
).toString();

const isTest = import.meta.env.MODE === "test";

const httpLink = createUploadLink({
  uri,
  fetch: isTest ? (...args) => fetchForMSW(...args) : undefined,
});

// https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error,
  },
});

const sentryLink = new SentryLink({
  uri,
  attachBreadcrumbs: {
    includeVariables: true,
    includeError: true,
  },
});

let tokenRefreshPromise: Promise<unknown> = Promise.resolve();

const shouldAttemptRefresh = (error: GraphQLError | GraphQLFormattedError) =>
  (error.extensions?.code as string) === "UNAUTHENTICATED" &&
  error.message !== CUSTOM_LOGIN_ERROR_MESSAGE;

const isLoginFailedError = (error: GraphQLError | GraphQLFormattedError) =>
  (error.extensions?.code as string) === "INTERNAL_SERVER_ERROR" &&
  error.message === CUSTOM_LOGIN_ERROR_MESSAGE;

const isOverQuotaError = (error: GraphQLError | GraphQLFormattedError) =>
  (error.extensions?.code as string) === "INTERNAL_SERVER_ERROR" &&
  error.message === OVER_QUOTA_ERROR_MESSAGE;

const isVehicleArchivedError = (error: GraphQLError | GraphQLFormattedError) =>
  (error.extensions?.code as string) === "INTERNAL_SERVER_ERROR" &&
  error.message === VEHICLE_ARCHIVED_ERROR_MESSAGE;

export class ApolloClientProvider {
  refreshService: RefreshTokenService;

  constructor(private authenticationService: AuthenticationService) {
    this.refreshService = new RefreshTokenService(this.authenticationService);

    PubSub.subscribe(UserTopics.TokenExpired, () =>
      this.refreshService.refreshTokens(),
    );
  }

  getApolloClient() {
    const authMiddleware = new ApolloLink((operation, forward) => {
      operation.setContext(({ headers = {} }) => {
        const transactionId = v4();

        setTag("transactionId", transactionId);

        const token = this.authenticationService.getAccessToken();

        const authorization = token ? `Bearer ${token}` : null;

        return {
          headers: {
            ...headers,
            "x-transaction-id": transactionId,
            "Apollo-Require-Preflight": "true",
            authorization,
          },
        };
      });

      return forward(operation);
    });

    const errorLink = onError(
      ({
        graphQLErrors,
        networkError,
        operation,
        forward,
      }): Observable<FetchResult> | void => {
        if (graphQLErrors) {
          for (const error of graphQLErrors) {
            if (shouldAttemptRefresh(error)) {
              console.debug("Attempting to refresh token", error);

              tokenRefreshPromise = !this.refreshService.isRefreshing
                ? this.refreshService.refreshTokens()
                : Promise.resolve();

              return promiseToObservable(tokenRefreshPromise).flatMap(() =>
                forward(operation),
              );
            }

            if (REACT_APP_SENTRY_ENVIRONMENT) {
              withScope((scope) => {
                const { operationName, variables, query } = operation;
                scope.setTag("kind", "GraphQL");
                scope.setContext("GraphQL", {
                  operationName,
                  variables,
                  query: query.loc?.source.body,
                });

                captureMessage(error.message, "error");
              });
            }

            if (isOverQuotaError(error)) {
              PubSub.publish(UserTopics.OverQuota);
            } else if (isVehicleArchivedError(error)) {
              PubSub.publish(UserTopics.Unauthorized);
            } else if (
              !isLoginFailedError(error) &&
              isErrorWithMessage(error)
            ) {
              PubSub.publish(AppTopics.Error, error.message);
            }
          }
        }

        if (networkError) {
          if (REACT_APP_SENTRY_ENVIRONMENT) {
            const { name, message, stack } = networkError;
            withScope((scope) => {
              scope.setTag("kind", "Network");
              scope.setContext("Error", { name, message, stack });
              captureException(networkError);
            });
          }
          console.error(networkError);
        }
      },
    );

    return new ApolloClient({
      link: from([sentryLink, errorLink, authMiddleware, retryLink, httpLink]),
      cache: inMemoryCache,
      devtools: {
        enabled: isDevelopment,
      },
      defaultOptions: {
        mutate: { errorPolicy: "all" },
        watchQuery: {
          fetchPolicy: "cache-and-network",
          nextFetchPolicy(currentFetchPolicy) {
            if (currentFetchPolicy === "cache-and-network") {
              // Demote cache-and-network
              return "cache-first";
            }
            // Leave all other fetch policies unchanged
            return currentFetchPolicy;
          },
        },
      },
    });
  }
}
