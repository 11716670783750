import { lazy } from "react";
import { Route } from "react-router-dom";

import { AuthenticatedGuard } from "components/AuthenticatedGuard/AuthenticatedGuard";
import { Dialogs } from "components/dialogs/Dialogs";
import {
  ADMIN,
  DOCUMENT,
  ONBOARDING,
  ORGANIZATION,
  SETTINGS,
  SUBSCRIBE,
  // TODO: transactions are temporarily disabled
  // TRANSACTIONS,
} from "constants/routes";
import AuthenticatedLayout from "pages/AuthenticatedLayout";

const AdminRoutes = lazy(() => import("pages/admin/AdminRoutes"));
const DocumentRoutes = lazy(() => import("pages/document/DocumentRoutes"));

const CollectorContentRoutes = lazy(
  () => import("pages/CollectorContentRoutes"),
);
// TODO: transactions are temporarily disabled
// const TransactionsRoutes = lazy(
//   () => import("pages/Transactions/TransactionsRoutes"),
// );
const SettingsRoutes = lazy(() => import("pages/settings/SettingsRoutes"));
const SubscribeRoutes = lazy(() => import("pages/subscribe/SubscribeRoutes"));
const OrganizationRoutes = lazy(
  () => import("pages/organization/OrganizationRoutes"),
);
const OnboardingRoutes = lazy(
  () => import("pages/onboarding/OnboardingRoutes"),
);
const ViewPage = lazy(() => import("pages/view/ViewPage"));

export const AuthenticatedRoutes = () => (
  <>
    <Route element={<DocumentRoutes />} path={`${DOCUMENT}/*`} />

    <Route element={<AuthenticatedLayout maxWidth={false} />}>
      <Route element={<AdminRoutes />} path={`${ADMIN}/*`} />
    </Route>

    <Route element={<AuthenticatedLayout maxWidth="md" />}>
      <Route element={<SettingsRoutes />} path={`${SETTINGS}/*`} />
    </Route>

    <Route element={<AuthenticatedLayout maxWidth="xl" />}>
      <Route element={<Dialogs />}>
        <Route element={<OrganizationRoutes />} path={`${ORGANIZATION}/*`} />

        <Route element={<OnboardingRoutes />} path={`${ONBOARDING}/*`} />

        <Route element={<SubscribeRoutes />} path={`${SUBSCRIBE}/*`} />

        <Route element={<AuthenticatedGuard roles />}>
          <Route element={<ViewPage />} path="/transfer/:token" />
        </Route>

        <Route element={<CollectorContentRoutes />} path="/*" />

        {/* TODO: transactions are temporarily disabled */}
        {/* <Route element={<TransactionsRoutes />} path={`${TRANSACTIONS}/*`} /> */}
      </Route>
    </Route>
  </>
);
