import { ApolloClient, gql } from "@apollo/client";

import { USER_FIELDS } from "constants/fragments";
import { Admin } from "typings/Admin";
import { AdminService } from "typings/AdminService";

const FETCH_DETAILS = gql`
  ${USER_FIELDS}
  query FetchDetails {
    admin {
      ...UserFields
    }
  }
`;

export default class ApolloAdminService implements AdminService {
  constructor(private apolloClient: ApolloClient<any>) {}

  async fetch(): Promise<Admin> {
    const { data, errors } = await this.apolloClient.query<{
      admin: Admin;
    }>({
      query: FETCH_DETAILS,
    });

    if (errors) {
      throw errors[0];
    }

    return data.admin;
  }
}
