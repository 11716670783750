import { Suspense } from "react";

import { DelayedFallback } from "./DelayedFallback";

export const SuspenseWrapper = ({
  children,
  fallback = <DelayedFallback />,
}: {
  children: React.ReactNode;
  fallback?: React.ReactNode;
}) => {
  return <Suspense fallback={fallback}>{children}</Suspense>;
};
