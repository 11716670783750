import { Observable } from "@apollo/client";

export const promiseToObservable = (promise: Promise<unknown>) =>
  new Observable((subscriber) => {
    promise.then(
      (value: unknown) => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      (err: unknown) => subscriber.error(err),
    );
  });
