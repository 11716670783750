import { useEffect } from "react";
import { useResolve } from "react-jpex";
import {
  EventSourceController,
  EventSourcePlus,
  OnResponseErrorContext,
  SseMessage,
} from "event-source-plus";

import { AuthenticationService } from "typings/AuthenticationService";
import { appTopicWithLayout, EventTopics, UserTopics } from "typings/Topics";

const { REACT_APP_API_URL } = import.meta.env;

const url = new URL(
  "/events",
  REACT_APP_API_URL ?? "http://localhost:3000",
).toString();

type CustomController = EventSourceController & { close: () => void };

export const useEventSource = () => {
  const authenticationService = useResolve<AuthenticationService>(
    "AuthenticationService",
  );

  useEffect(() => {
    const getHeaders = () => {
      return {
        authorization: `Bearer ${authenticationService.getAccessToken()}`,
      };
    };

    let controller: CustomController | null;

    const initController = () => {
      const source = new EventSourcePlus(url, {
        headers: getHeaders,
      });

      const controller = source.listen({
        onMessage: ({ data }: SseMessage) => {
          try {
            const payload = JSON.parse(data);

            if (payload.layout) {
              const topic = appTopicWithLayout(payload.layout);

              PubSub.publish(topic, payload);
            }

            if (EventTopics[payload.eventName as keyof typeof EventTopics]) {
              PubSub.publish(payload.eventName, payload);
            }
          } catch (error) {
            console.error("Error parsing data", error);
          }
        },
        onRequestError: (context) => {
          console.error("Request Error", context.error);
        },
        onResponseError(context: OnResponseErrorContext) {
          console.error("Response Error", context);
          if (context.response.status === 401) {
            PubSub.publish(UserTopics.TokenExpired);
          }
        },
      });

      const custom = controller as CustomController;

      custom.close = () => controller.abort("unloading window");

      window.removeEventListener("beforeunload", custom.close);
      window.addEventListener("beforeunload", custom.close);

      return custom;
    };

    const loggedIn = PubSub.subscribe(UserTopics.LoggedIn, () => {
      controller = initController();
    });

    const signedUp = PubSub.subscribe(UserTopics.SignedUp, () => {
      controller = initController();
    });

    const loggedOut = PubSub.subscribe(UserTopics.LoggedOut, () => {
      controller?.abort(UserTopics.LoggedOut);
    });

    if (authenticationService.isAuthenticated()) {
      controller = initController();
    }

    return () => {
      if (controller) {
        controller.abort("useEventSource cleanup");

        window.removeEventListener("beforeunload", controller.close);
      }

      PubSub.unsubscribe(loggedOut);
      PubSub.unsubscribe(loggedIn);
      PubSub.unsubscribe(signedUp);
    };
  }, []);
};
