import { addSearchParams } from "./url";

export const isMobileDevice = (): boolean =>
  (typeof window !== "undefined" &&
    typeof window.screen.orientation !== "undefined") ||
  (typeof navigator !== "undefined" &&
    navigator.userAgent.indexOf("IEMobile") !== -1);

export const openURL = (url: string) => window.open(url);

type ReloadParams = {
  force?: boolean;
};

export const TIMESTAMP_QUERY_PARAM = "timestamp";

export const reload = ({ force = false }: ReloadParams = {}) => {
  if (force) {
    const originUrl = new URL(window.location.href);

    const replaceUrl = addSearchParams(originUrl, {
      [TIMESTAMP_QUERY_PARAM]: Date.now().toString(),
    });

    window.location.replace(replaceUrl);
  } else {
    window.location.reload();
  }
};
