import { t } from "i18next";

import { BORDER_RADIUS_PX, BUTTON_BORDER_RADIUS_PX } from "constants/layout";
import {
  ACME_PALETTE,
  ASCARI_PALETTE,
  BI_PALETTE,
  CURATED_PALETTE,
  DRIVEN_DARK_PALETTE,
  DRIVEN_LIGHT_PALETTE,
  ILR_PALETTE,
  KESSEL_PALETTE,
} from "constants/palette";
import {
  ASCARI_FONT_FAMILY,
  BI_FONT_FAMILY,
  DRIVEN_FONT_FAMILY,
  KESSEL_FONT_FAMILY,
  TYPOGRAPHY,
} from "constants/typography";
import { AppTheme } from "typings/AppTheme";
import { Organizations } from "typings/Organization";

export const borderRadiusByAppTheme: Record<AppTheme, number> = {
  [AppTheme.ACME]: 4,
  [AppTheme.ASCARI_LIGHT]: BUTTON_BORDER_RADIUS_PX,
  [AppTheme.BI_DARK]: BORDER_RADIUS_PX,
  [AppTheme.CURATED_LIGHT]: BORDER_RADIUS_PX,
  [AppTheme.DRIVEN_DARK]: BORDER_RADIUS_PX,
  [AppTheme.DRIVEN_LIGHT]: BORDER_RADIUS_PX,
  [AppTheme.ILR_DARK]: BORDER_RADIUS_PX,
  [AppTheme.KESSEL_DARK]: 0,
};

export const buttonBorderRadiusByAppTheme: Record<AppTheme, number> = {
  [AppTheme.ACME]: 4,
  [AppTheme.ASCARI_LIGHT]: BUTTON_BORDER_RADIUS_PX,
  [AppTheme.BI_DARK]: BUTTON_BORDER_RADIUS_PX,
  [AppTheme.CURATED_LIGHT]: BUTTON_BORDER_RADIUS_PX,
  [AppTheme.DRIVEN_DARK]: BUTTON_BORDER_RADIUS_PX,
  [AppTheme.DRIVEN_LIGHT]: BUTTON_BORDER_RADIUS_PX,
  [AppTheme.ILR_DARK]: BUTTON_BORDER_RADIUS_PX,
  [AppTheme.KESSEL_DARK]: 0,
};

export const paletteByAppTheme: Record<AppTheme, any> = {
  [AppTheme.ACME]: ACME_PALETTE,
  [AppTheme.ASCARI_LIGHT]: ASCARI_PALETTE,
  [AppTheme.BI_DARK]: BI_PALETTE,
  [AppTheme.CURATED_LIGHT]: CURATED_PALETTE,
  [AppTheme.DRIVEN_DARK]: DRIVEN_DARK_PALETTE,
  [AppTheme.DRIVEN_LIGHT]: DRIVEN_LIGHT_PALETTE,
  [AppTheme.ILR_DARK]: ILR_PALETTE,
  [AppTheme.KESSEL_DARK]: KESSEL_PALETTE,
};

export const selectionColorByAppTheme: Record<AppTheme, string> = {
  [AppTheme.ACME]: ACME_PALETTE.primary.main,
  [AppTheme.ASCARI_LIGHT]: ASCARI_PALETTE.primary.main,
  [AppTheme.BI_DARK]: BI_PALETTE.primary.dark,
  [AppTheme.CURATED_LIGHT]: CURATED_PALETTE.primary.main,
  [AppTheme.DRIVEN_DARK]: DRIVEN_DARK_PALETTE.primary.main,
  [AppTheme.DRIVEN_LIGHT]: DRIVEN_LIGHT_PALETTE.primary.main,
  [AppTheme.ILR_DARK]: ILR_PALETTE.primary.main,
  [AppTheme.KESSEL_DARK]: KESSEL_PALETTE.primary.dark,
};

export const typographyByAppTheme: Record<AppTheme, any> = {
  [AppTheme.ACME]: {
    ...TYPOGRAPHY,
    fontFamily: DRIVEN_FONT_FAMILY,
  },
  [AppTheme.CURATED_LIGHT]: {
    ...TYPOGRAPHY,
    fontFamily: DRIVEN_FONT_FAMILY,
  },
  [AppTheme.DRIVEN_LIGHT]: {
    ...TYPOGRAPHY,
    fontFamily: DRIVEN_FONT_FAMILY,
  },
  [AppTheme.DRIVEN_DARK]: {
    ...TYPOGRAPHY,
    fontFamily: DRIVEN_FONT_FAMILY,
  },
  [AppTheme.BI_DARK]: {
    ...TYPOGRAPHY,
    fontFamily: BI_FONT_FAMILY,
  },
  [AppTheme.KESSEL_DARK]: {
    ...TYPOGRAPHY,
    fontFamily: KESSEL_FONT_FAMILY,
  },
  [AppTheme.ILR_DARK]: {
    ...TYPOGRAPHY,
    fontFamily: DRIVEN_FONT_FAMILY,
  },
  [AppTheme.ASCARI_LIGHT]: {
    ...TYPOGRAPHY,
    fontFamily: ASCARI_FONT_FAMILY,
  },
};

export const organizationByAppTheme: Record<AppTheme, Organizations> = {
  [AppTheme.ACME]: Organizations.ACME,
  [AppTheme.ASCARI_LIGHT]: Organizations.ASCARI,
  [AppTheme.BI_DARK]: Organizations.BI,
  [AppTheme.CURATED_LIGHT]: Organizations.CURATED,
  [AppTheme.DRIVEN_DARK]: Organizations.DRIVEN,
  [AppTheme.DRIVEN_LIGHT]: Organizations.DRIVEN,
  [AppTheme.ILR_DARK]: Organizations.ILR,
  [AppTheme.KESSEL_DARK]: Organizations.KESSEL,
};

export const defaultAppThemeByOrganization: Record<Organizations, AppTheme> = {
  [AppTheme.ACME]: Organizations.ACME,
  [Organizations.ASCARI]: AppTheme.ASCARI_LIGHT,
  [Organizations.BI]: AppTheme.BI_DARK,
  [Organizations.CURATED]: AppTheme.CURATED_LIGHT,
  [Organizations.DRIVEN]: AppTheme.DRIVEN_DARK,
  [Organizations.ILR]: AppTheme.ILR_DARK,
  [Organizations.KESSEL]: AppTheme.KESSEL_DARK,
};

export const pathByAppTheme: Record<AppTheme, string> = {
  [AppTheme.ACME]: "/acme",
  [AppTheme.ASCARI_LIGHT]: "/ascari",
  [AppTheme.BI_DARK]: "/bi",
  [AppTheme.CURATED_LIGHT]: "/curated",
  [AppTheme.DRIVEN_DARK]: "/driven",
  [AppTheme.DRIVEN_LIGHT]: "/driven",
  [AppTheme.ILR_DARK]: "/ilr",
  [AppTheme.KESSEL_DARK]: "/driven",
};

export const organizationTitleByAppTheme: Record<AppTheme, string> = {
  [AppTheme.ACME]: t("organization.acme"),
  [AppTheme.ASCARI_LIGHT]: t("organization.ascari"),
  [AppTheme.BI_DARK]: t("organization.bi"),
  [AppTheme.CURATED_LIGHT]: t("organization.curated"),
  [AppTheme.DRIVEN_DARK]: t("organization.driven"),
  [AppTheme.DRIVEN_LIGHT]: t("organization.driven"),
  [AppTheme.ILR_DARK]: t("organization.ilr"),
  [AppTheme.KESSEL_DARK]: t("organization.kessel"),
};
