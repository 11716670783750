import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import debounce from "lodash.debounce";

import { EmptyBlockIcon } from "components/EmptyBlockIcon/EmptyBlockIcon";
import { OtpForm } from "components/forms/OtpForm";
import { MFA_METHOD_OPTIONS } from "constants/mfaMethods";
import { MAILTO_SUPPORT } from "constants/routes";
import { useMessaging } from "contexts/Messaging/useMessagingContext";
import { useConfirmMfa } from "hooks/useConfirmMfa";

import { useMfaMethods } from "./useMfaMethods";

export const ConfirmMfaForm = () => {
  const { t } = useTranslation();

  const { enqueueMessage } = useMessaging();

  const { mfaMethod, mfaEnabledMethods, handleUseMethod, qrCode } =
    useMfaMethods();

  const {
    handleConfirmMfa,
    state: { loading, error, errorMethod },
  } = useConfirmMfa();

  useEffect(() => {
    if (error && errorMethod === "webauthn" && !loading) {
      enqueueMessage({
        text: t("mfaDeviceAuthenticationError"),
        variant: "error",
      });
    }
  }, [error, errorMethod, loading]);

  if (!mfaMethod) return <CircularProgress />;

  const isWebAuthn = mfaMethod === "webauthn";

  const isEmail = mfaMethod === "email";

  return (
    <Stack alignItems="center" spacing={2}>
      <EmptyBlockIcon icon={MFA_METHOD_OPTIONS[mfaMethod].icon} />

      {!isWebAuthn && (
        <>
          <Typography
            color="text.primary"
            component="div"
            textAlign="center"
            variant="body1"
          >
            <Trans i18nKey={MFA_METHOD_OPTIONS[mfaMethod].claimKey}>
              Please, introduce the 6-digit code from your 2FA method
            </Trans>
          </Typography>

          <OtpForm
            disabled={loading}
            error={!!error && errorMethod !== "webauthn"}
            onSubmit={(code: string) => handleConfirmMfa(mfaMethod, code)}
          />
        </>
      )}

      {isWebAuthn && (
        <Button
          disabled={loading}
          variant="contained"
          onClick={() => handleConfirmMfa(mfaMethod, qrCode)}
        >
          {t("startVerification")}
        </Button>
      )}

      <Stack alignItems="flex-start" spacing={1} width="100%">
        <Typography variant="body1">
          {t(MFA_METHOD_OPTIONS[mfaMethod].cantAccessKey)}
        </Typography>
        {mfaEnabledMethods
          .filter((method) => method !== mfaMethod)
          .map((method) => (
            <Button
              key={method}
              variant="text"
              onClick={() => handleUseMethod(method)}
            >
              {t(MFA_METHOD_OPTIONS[method].useKey)}
            </Button>
          ))}

        {isEmail && (
          <Button
            size="small"
            variant="text"
            onClick={debounce(async () => handleUseMethod("email", true), 1000)}
          >
            {t("resendEmail")}
          </Button>
        )}

        <Button
          size="small"
          variant="text"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = MAILTO_SUPPORT;
          }}
        >
          {t("contactDriven")}
        </Button>
      </Stack>
    </Stack>
  );
};
