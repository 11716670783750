import { Helmet } from "react-helmet-async";

import { DEFAULT_APP_THEME } from "constants/settings";
import { paletteByAppTheme, pathByAppTheme } from "constants/theming";
import { useAppTheme } from "contexts/useAppThemeContext";

export const AppMeta = () => {
  const { appTheme } = useAppTheme();

  const faviconPath = pathByAppTheme[appTheme || DEFAULT_APP_THEME];

  return (
    <Helmet>
      <meta
        content={paletteByAppTheme[appTheme].background.nav}
        name="theme-color"
      />
      <meta
        content={paletteByAppTheme[appTheme].background.default}
        name="background-color"
      />
      <link
        href={`${faviconPath}/apple-touch-icon.png`}
        rel="apple-touch-icon"
        sizes="180x180"
      />
      <link
        href={`${faviconPath}/favicon-32x32.png`}
        rel="icon"
        sizes="32x32"
        type="image/png"
      />
      <link
        href={`${faviconPath}/favicon-16x16.png`}
        rel="icon"
        sizes="16x16"
        type="image/png"
      />
      <link
        color={paletteByAppTheme[appTheme].primary.main}
        href={`${faviconPath}/safari-pinned-tab.svg`}
        rel="mask-icon"
      />
      <link href={`${faviconPath}/app.webmanifest`} rel="manifest" />
    </Helmet>
  );
};
