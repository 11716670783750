import { Navigate, Outlet } from "react-router-dom";

import { SuspenseWrapper } from "components/Layout/SuspenseWrapper";
import { MAINTENANCE } from "constants/routes";
import { maintenanceEnabled } from "utils/featureFlags";

export const MaintenanceGuard = () => {
  if (maintenanceEnabled) {
    return <Navigate to={MAINTENANCE} />;
  }

  return (
    <SuspenseWrapper>
      <Outlet />
    </SuspenseWrapper>
  );
};
