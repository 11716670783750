import { t } from "i18next";

const TEN_MB = 10485760;

export const MAX_FILE_SIZE = TEN_MB;

export class FileOversizedError extends Error {
  constructor(m = "error.media.fileOversize") {
    super(t(m, { maxSize: `10 MB` }));
  }
}
