import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

export const LoadingSpinner = () => (
  <Stack
    data-testid="loading"
    sx={{
      width: "100%",
      height: (theme) => theme.spacing(40),
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <CircularProgress color="primary" size={40} />
  </Stack>
);
